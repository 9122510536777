import { Navigate } from "react-router-dom";
import { useAuth } from "src/contexts/AuthContext";
import usePermissions from "src/hooks/usePermissions/usePermissions";

function RestrictedRoute(props) {
  const { isUserLoggedIn, currentUser } = useAuth();
  const { isUserActionAllowed } = usePermissions();
  const userAllowed = isUserActionAllowed(props.action);

  if (currentUser === undefined) return;

  if (!userAllowed || !isUserLoggedIn) {
    return <Navigate replace to="/" />;
  }

  return props.children;
}

export default RestrictedRoute;
