import { NavLink } from "react-router-dom";
import { useTransitionNavigate } from "src/hooks";

const TransitionNavLink = (props) => {
  const { transition = true, to, onClick, children, state, ...rest } = props;
  const navigate = useTransitionNavigate();

  const onLinkClick = (event) => {
    if (transition) {
      event.preventDefault();
      navigate(to, { ...(state && { state: state }) });
    }
    onClick?.(event);
  };

  return (
    <NavLink
      to={to}
      onClick={onLinkClick}
      {...(state && { state: state })}
      {...rest}
    >
      {children}
    </NavLink>
  );
};

export default TransitionNavLink;
