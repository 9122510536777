import classNames from "classnames";
import { useCallback } from "react";
import TransitionNavLink from "../../../TransitionNavLink/TransitionNavLink";
import "./Menuitem.scss";

function MenuItem(props) {
  const menuItemClass = classNames(
    "MenuItem",
    {
      "MenuItem--isDisplay": props.isOpen,
    },
    props.className
  );
  return (
    <li className={menuItemClass}>
      <TransitionNavLink
        to={props?.path}
        {...(props.transition !== undefined && {
          transition: props.transition,
        })}
        className={useCallback(
          ({ isActive }) =>
            classNames(
              "MenuItem__link",
              {
                "MenuItem__link--active": isActive,
                "MenuItem__link--isDisplay": props.isOpen,
              },
              props.className
            ),
          [props.isOpen, props.className]
        )}
        onClick={props.onClick}
        // state={{ isModal: !!props.isModal }}
        state={props.state}
      >
        {props.text}
      </TransitionNavLink>
    </li>
  );
}

export default MenuItem;
