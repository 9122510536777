// import parse from "html-react-parser";
// svg below is taken from:
// https://www.veryicon.com/
import { ReactComponent as EventIcon } from "src/assets/icons/event-5.svg";
import { ReactComponent as PersonIcon } from "src/assets/icons/person-5.svg";
import { ReactComponent as FolderOpenIcon } from "src/assets/icons/md-folder-open-1.svg";
import usePost from "../../usePost";
import { usePostsContext } from "../../../context/PostsProvider/PostsProvider";
import { StringToJSX } from "../../../../../tools/StringToJSX";
import { dateOptions } from "../../../common/dateOptions";
import { H1, H2 } from "src/elements/Typography";
import { stripTagsFromString, shortenString } from "src/tools/other";
import { MetaSEO } from "src/components";
import "./ViewPost.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

function ViewPost(props) {
  const posts = usePostsContext();
  const { post, titleDashes } = usePost(posts);
  const lenOfStr = 120;
  let shortBody = stripTagsFromString(post.body);
  shortBody = shortenString(shortBody, lenOfStr);
  const { currentTitleDashes } = useParams();
  const navigate = useNavigate();
  console.log(currentTitleDashes);
  console.log(titleDashes);

  useEffect(() => {
    if (currentTitleDashes !== titleDashes) {
      navigate(`../${titleDashes}`, { replace: true });
    }
  }, []);

  return (
    <article className="ViewPost">
      <MetaSEO title={post.title} description={shortBody} image={post.url} />
      <header className="ViewPost__header">
        <H1 className="ViewPost__title">{post.title}</H1>
        <section className="ViewPost__headerProperties">
          <EventIcon className="ViewPost__svg" />
          <span className="ViewPost__headerSpan">
            {new Date(
              post.date?.toDate?.().toUTCString() || new Date(post.date)
            ).toLocaleDateString("en-GB", dateOptions)}
          </span>
          <PersonIcon className="ViewPost__svg" />
          <span className="ViewPost__headerSpan">{post.author}</span>
          <FolderOpenIcon className="ViewPost__svg" />
          <span className="ViewPost__headerSpan">{post.category}</span>
        </section>
      </header>
      <section className="ViewPost__body">
        {/* Sections with no headings do not appear in the document outline. */}
        {/* Because it I added hidden H2 */}
        {/* https://developer.mozilla.org/en-US/docs/Web/HTML/Element/section#examples */}
        <H2 className="ViewPost__body--isHide">Body Section</H2>
        {StringToJSX(post.body)}
        {/* {parse(props.post.body)} */}
      </section>
      {/* Other ways to add JSX in text to the page */}
      {/* <div className="viewPostBody">{StringToJSX(props.body)}</div> */}
      {/* <div className="viewPostBody">{props.body}</div> */}
      {/* <div
        className="viewPostBody"
        dangerouslySetInnerHTML={{ __html: props.body }}
      /> */}
      {/*https://stackoverflow.com/questions/40952434/how-do-i-display-the-content-of-react-quill-without-the-html-markup/71498900#71498900 */}
    </article>
  );
}

export default ViewPost;
