import makeContext from "src/makeContext";
import usePostsProvider from "./usePostsProvider";

const [StateProvider, usePostsContext, usePostsContextUpdater] = makeContext(
  []
);

export { usePostsContext, usePostsContextUpdater };

function PostsProvider({ children }) {
  const { posts, setPosts } = usePostsProvider();

  return (
    <StateProvider store={posts} setStore={setPosts}>
      {children}
    </StateProvider>
  );
}

export default PostsProvider;
