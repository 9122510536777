import { isObjectEmpty } from "../../isObjectEmpty";

export class BuildInURL {
  getURL() {
    this.url.search = this.queryParams.toString();
    return this.url.href;
  }
  setURL(url, queryParams = {}) {
    this.url = new URL(url);
    this.queryParams = new URLSearchParams(
      isObjectEmpty(queryParams) ? this.url.search : queryParams
    );
  }
  appendQueryParam(key, value) {
    this.queryParams.append(key, value);
  }
  setQueryParam(key, value) {
    this.queryParams.set(key, value);
  }
  getQueryParam(key) {
    return this.queryParams.get(key);
  }
  static objectToQueryParams(obj) {
    return new URLSearchParams(obj).toString();
  }
  static getCurrentUrl() {
    return window.location.href;
  }
}
