export class BuildInLocalStorage {
  getItem(key) {
    return localStorage.getItem(key);
  }
  setItem(item, value) {
    localStorage.setItem(item, JSON.stringify(value));
  }
  removeItem(key) {
    localStorage.removeItem(key);
  }
}

export const singletonBuildInLocalStorage = new BuildInLocalStorage();
