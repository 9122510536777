import { useCallback, useState } from "react";
// import { useMedia } from "react-media";
// import { mainRoutes } from "src/pages/main.route.js";
import { getCssVariableValue } from "src/tools/other";

function useNavBar(props) {
  const menuItems = props.links.filter((obj) => obj.isNavBarLink);
  const [open, setOpen] = useState(false);
  const break1 = getCssVariableValue("--break1");
  // const isSmallScreen = useMedia({ query: break1 });
  const isSmallScreen = window.matchMedia(break1).matches;

  const handleClick = useCallback(() => {
    isSmallScreen && setOpen(!open);
  }, [isSmallScreen, open]);

  return { menuItems, open, handleClick, isSmallScreen };
}

export default useNavBar;
