import { lazy, Suspense } from "react";
import { postsRoutes } from "./Posts/Posts.route.js";
import App from "src/App.js";
import { GlobalContexts } from "src/components";

const Home = lazy(() =>
  import(/* webpackChunkName: 'Home' */ "src/pages/Home")
);

export const mainRoutes = [
  {
    element: <GlobalContexts />,
    children: [
      {
        element: (
          <Suspense>
            <App />
          </Suspense>
        ),
        children: [
          {
            linkText: "Home",
            chunkName: "Home",
            isNavBarLink: true,
            path: "",
            element: <Home />,
          },
          {
            linkText: "Posts",
            chunkName: "Posts",
            isNavBarLink: true,
            data: {
              url: "https://firestore.googleapis.com/v1/projects/blog-d518e/databases/(default)/documents/posts?orderBy=date+desc&pageSize=100",
              crossorigin: "anonymous",
            },
            path: "posts/*",
            children: postsRoutes,
          },
        ],
      },
    ],
  },
];
