// https://www.freecodecamp.org/news/react-helmet-examples/
// https://github.com/americanexpress/react-seo/blob/main/src/components/SEO.jsx
// https://github.com/theninthsky/frontend-essentials/blob/main/src/components/Meta.tsx

import { Helmet } from "react-helmet";

function MetaSEO(props) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      {props.image && <meta property="og:image" content={props.image} />}
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={props.description} />
      {props.image && <meta name="twitter:image" content={props.image} />}
      {/* End Twitter tags */}
    </Helmet>
  );
}

export default MetaSEO;
