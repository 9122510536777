import { Outlet } from "react-router-dom";
import AuthDialogProvider from "src/contexts/AuthDialog/AuthDialog.js";
import { AuthProvider } from "src/contexts/AuthContext";

function GlobalContexts() {
  return (
    <AuthProvider>
      <AuthDialogProvider>
        <Outlet />
      </AuthDialogProvider>
    </AuthProvider>
  );
}

export default GlobalContexts;
