import { Suspense } from "react";
import { lazy } from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
// import { StableNavigateContextProvider } from "./contexts/StableNavigateContext/StableNavigateContext";
import { useStateContext } from "src/contexts/AuthDialog/AuthDialog";
import { mainRoutes } from "src/pages/main.route";
import "./styles/main-css-variables.scss";
import "./App.scss";

const Auth = lazy(() =>
  import(/* webpackChunkName: 'Auth' */ "src/components/Auth/Auth")
);

function App(props) {
  const links = mainRoutes[0].children[0].children;
  const dialogIsOpen = useStateContext();
  return (
    <div className="app">
      {/* <StableNavigateContextProvider> */}
      <header className="app__header">
        <NavBar links={links} />
      </header>
      <div className="app__body">
        <Suspense>
          <Outlet />
          {dialogIsOpen && <Auth />}
        </Suspense>
      </div>
      {/* </StableNavigateContextProvider> */}
    </div>
  );
}

export default App;

// a good example of blog website in React
// https://www.youtube.com/watch?v=1qZE0HUclfA
// https://www.freecodecamp.org/news/fullstack-react-blog-app-with-express-and-psql/
// https://dev.to/kingdavid/learn-react-hook-by-building-a-simple-blog-app-22i2

// Top 15: Best Rich Text Editor Components (WYSIWYG) for ReactJS
// https://ourcodeworld.com/articles/read/1065/top-15-best-rich-text-editor-components-wysiwyg-for-reactjs

// firebase cloud example
// https://youtu.be/B-kxUMHBxNo?t=10582
// https://youtu.be/B-kxUMHBxNo?t=12276

// stop here
// https://firebase.google.com/codelabs/firestore-web#9
// https://firebase.google.com/docs/firestore/security/get-started
// https://firebase.google.com/docs/firestore/quickstart
// https://cloud.google.com/firestore/docs/client/get-firebase

// Basic examples of using Cloud Firestore Security Rules
// https://khreniak.medium.com/cloud-firestore-security-rules-basics-fac6b6bea18e
