import {
  getDocumentId,
  convertFromDocument,
  getBaseFirebaseDbUrl,
} from "./common";
import { URLEntity } from "../../UrlEntity/BuildInURL";

const url = new URLEntity();

export const fetchAllDocs = async (
  collection,
  orderObj,
  { httpClient, user }
) => {
  try {
    const baseURL = getBaseFirebaseDbUrl(collection);
    let httpOptions = {
      method: "GET",
    };
    httpOptions = await user.pairAuthToHttpRequest(httpOptions);
    const queryParams = {
      orderBy: `${orderObj.field} ${orderObj.direction}`,
      pageSize: 100,
    };
    url.setURL(baseURL, queryParams);
    const response = await httpClient.request(url.getURL(), httpOptions);
    return response.documents.map((doc) => ({
      id: getDocumentId(doc),
      data: convertFromDocument(doc),
    }));
  } catch (error) {
    console.log("Error getting documents:", error);
    throw error;
  }
};
