//mixed of websites:
// https://blog.logrocket.com/a-deep-dive-into-react-context-api/
// https://kentcdodds.com/blog/how-to-use-react-context-effectively

import { createContext, useContext } from "react";

export default function makeContext() {
  const StateContext = createContext(undefined);
  const StateUpdaterContext = createContext(undefined);

  function StateProvider({ store, setStore, children }) {
    return (
      <StateContext.Provider value={store}>
        <StateUpdaterContext.Provider value={setStore}>
          {children}
        </StateUpdaterContext.Provider>
      </StateContext.Provider>
    );
  }

  function useStateContext() {
    const context = useContext(StateContext);
    if (context === undefined) {
      throw new Error("useStateContext must be used within a StateProvider");
    }
    return context;
  }

  function useStateContextUpdater() {
    const context = useContext(StateUpdaterContext);
    if (context === undefined) {
      throw new Error(
        "useStateContextUpdater must be used within a StateProvider"
      );
    }
    return context;
  }
  return [StateProvider, useStateContext, useStateContextUpdater];
}
