import { HttpClient } from "../../HttpClient/BuildInFetch";
import { URLEntity } from "../../UrlEntity/BuildInURL";
import { singletonStorage } from "../../BrowserStorage/BuildInLocalStorage";

const httpClient = new HttpClient();
const url = new URLEntity();

export class UserDetails {
  constructor() {
    this.destructObjectVariables({});
  }
  destructObjectVariables(responseObj) {
    this.idToken = responseObj.idToken;
    this.email = responseObj.email;
    this.localId = responseObj.localId;
    this.refreshToken = responseObj.refreshToken;
    this.expiresIn = Number(responseObj.expiresIn) || undefined;
    this.expirationTimeIDToken =
      Number(responseObj.expirationTimeIDToken) || undefined;
  }
  loadSignedInInfo(response) {
    this.destructObjectVariables(response);
    this.expirationTimeIDToken = Date.now() + this.expiresIn * 1000;
    singletonStorage.setItem("currentUser", this);
  }
  signInCachedUser() {
    const parsedCurrentUserObject =
      JSON.parse(singletonStorage.getItem("currentUser")) || {};
    this.destructObjectVariables(parsedCurrentUserObject);
  }
  signOut() {
    singletonStorage.removeItem("currentUser");
    this.destructObjectVariables({});
  }
  get isExpired() {
    return (
      !this.expirationTimeIDToken ||
      Date.now() > this.expirationTimeIDToken - 60_000
    );
  }
  // Firebase getIdToken function in the following link:
  // https://github.com/firebase/firebase-js-sdk/blob/b6c231a282313aeda59c447c24f71fdad35240bc/packages/auth/src/core/user/user_impl.ts#L98
  async getIdToken() {
    if (this.isExpired && this.refreshToken) {
      const NewIdTokenObj = await exchangeIdToken(this.refreshToken);
      this.idToken = NewIdTokenObj.id_token;
      this.expiresIn = Number(NewIdTokenObj.expires_in);
      this.expirationTimeIDToken = Date.now() + this.expiresIn * 1000;
      singletonStorage.setItem("currentUser", this);
    }
    if (this.refreshToken) {
      return this.idToken;
    }
    return null;
  }
  async pairAuthToHttpRequest(httpOptions) {
    const newHttpOptions = Object.assign({}, httpOptions);
    if (this.idToken) {
      // Fetch request with Authorization header will cause for Preflight request
      // So the default of withAPIKey will be false.
      // see the following website for further information about Authorization:
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#examples_of_access_control_scenarios
      if (!newHttpOptions["headers"]) newHttpOptions["headers"] = {};
      newHttpOptions["headers"][
        "Authorization"
      ] = `Bearer ${await this.getIdToken()}`;
    }
    return newHttpOptions;
  }
}

const getBaseFirebaseAuthTokenUrl = () => {
  const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
  const subDomain = "securetoken";
  const subDir = "token";
  const baseURL = `https://${subDomain}.googleapis.com/v1/${subDir}?key=${apiKey}`;
  return baseURL;
};

const exchangeIdToken = async (refreshToken) => {
  try {
    const reqBody = {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    };
    const reqBodyURLSearchParams = URLEntity.objectToQueryParams(reqBody);
    const baseURL = getBaseFirebaseAuthTokenUrl();
    url.setURL(baseURL);
    let httpOptions = {
      method: "POST",
      body: reqBodyURLSearchParams,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const response = await httpClient.request(url.getURL(), httpOptions);
    console.log("Exchange ID token was successful");
    return response;
  } catch (error) {
    console.error("Error exchange ID token: ", error.message);
  }
};
