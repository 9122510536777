import { createContext, useContext, useState } from "react";
import permissionsData from "../../permissions.json";
import { User } from "./tools/User";

const AuthContext = createContext(undefined);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider(props) {
  const { children } = props;
  const user = new User();
  user.signInCachedUser();
  const [currentUser, setCurrentUser] = useState(user);
  // const [loading, setLoading] = useState(true);
  const isUserLoggedIn = !!currentUser?.localId;

  // useEffect(() => {
  //   const init = () => {
  //     const user = new User();
  //     if (user.refreshToken) {
  //       setCurrentUser(user);
  //     }
  //     setLoading(false);
  //   };
  //   init();
  // }, []);

  const value = {
    permissionsData,
    currentUser,
    setCurrentUser,
    isUserLoggedIn,
  };

  return (
    <AuthContext.Provider value={value}>
      {/* {!loading && children} */}
      {children}
    </AuthContext.Provider>
  );
}
