import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { RestrictedRoute } from "src/components";
import PostsProvider from "./context/PostsProvider/PostsProvider.js";
import { postRoutes } from "./Post/Post.route.js";

const AllPosts = lazy(() =>
  import(/* webpackChunkName: 'AllPosts' */ "./AllPosts/AllPosts")
);
const AddPost = lazy(() =>
  import(/* webpackChunkName: 'AddPost' */ "./AddPost/AddPost")
);
const Post = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: 'Post' */ "./Post/Post")
);

export const postsRoutes = [
  {
    element: (
      <PostsProvider>
        <Outlet />
      </PostsProvider>
    ),
    children: [
      {
        name: "All Posts",
        chunkName: "AllPosts",
        element: <AllPosts />,
        index: true,
      },
      {
        name: "Add Post",
        chunkName: "AddPost",
        path: "addpost",
        element: (
          <RestrictedRoute action="AllPosts--addPost">
            <AddPost />
          </RestrictedRoute>
        ),
      },
      {
        name: "Post",
        chunkName: "Post",
        path: ":postid/*",
        element: <Post />,
        children: postRoutes,
      },
    ],
  },
];
