import { lazy, Suspense } from "react";
import classNames from "classnames";
// import AnimatedMenuIcon from "src/assets/icons/AnimatedMenuIcon/AnimatedMenuIcon";
import Menu from "./components/Menu/Menu";
import Menuitem from "./components/Menuitem/Menuitem";
import MenuAuthItem from "./components/MenuAuthItem/MenuAuthItem";
import { useScrolledDown } from "src/hooks";
import useNavBar from "./useNavBar";
import "./NavBar.scss";

const AnimatedMenuIcon = lazy(() =>
  import(
    /* webpackChunkName: 'NavBar__icons--small-screen' */ "src/assets/icons/AnimatedMenuIcon/AnimatedMenuIcon"
  )
);

function NavBar(props) {
  const { isScrolledDown } = useScrolledDown();
  const { open, menuItems, handleClick, isSmallScreen } = useNavBar(props);

  return (
    <nav className={classNames("NavBar", { "NavBar--scroll": isScrolledDown })}>
      <Menu className="NavBar__startSide" isOpen={open}>
        {menuItems.map((item, index) => {
          return (
            <Menuitem
              key={index}
              text={item.linkText}
              path={item.path.replace("/*", "")}
              isOpen={open}
              onClick={handleClick}
            />
          );
        })}
      </Menu>
      <Menu className="NavBar__endSide" isOpen={open}>
        <MenuAuthItem isOpen={open} onClick={handleClick} />
      </Menu>
      {isSmallScreen && (
        <div className="NavBar__icon">
          {/* <Suspense fallback={<></>}> */}
          <AnimatedMenuIcon
            className="NavBar__icon--white"
            open={open}
            onClick={handleClick}
          />
          {/* </Suspense> */}
        </div>
      )}
    </nav>
  );
}

export default NavBar;
