import classNames from "classnames";
import "./H2.css";

function H2(props) {
  const { children, className, ...rest } = props;
  return (
    <h2 className={classNames("H2", className)} {...rest}>
      {children}
    </h2>
  );
}

export default H2;
