import { startTransition } from "react";
import { useNavigate } from "react-router-dom";

const useTransitionNavigate = () => {
  const navigate = useNavigate();

  return (to, options) => startTransition(() => navigate(to, options));
};

export default useTransitionNavigate;
