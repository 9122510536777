export class BuildInFetch {
  constructor() {
    this.response = {};
    this.controller = new AbortController();
  }
  cancelRequest() {
    this.controller.abort();
  }
  async request(url, options) {
    try {
      const newHttpOptions = Object.assign({}, options);
      newHttpOptions["signal"] = this.controller.signal;
      const response = await fetch(url, newHttpOptions);
      let responseJson = await response.json();
      if (response.ok) {
        this.response = responseJson;
        return responseJson;
      } else {
        throw responseJson.error;
      }
    } catch (error) {
      // https://wanago.io/2022/04/11/abort-controller-race-conditions-react/
      // if (controller?.signal?.aborted) {
      //   console.log("The user aborted the request");
      // } else {
      //   console.error("The request failed");
      // }
      throw error;
    }
  }
}
