// Created a class because I wanted each object that I will create to reference the toDate function.
class CustomDate extends Date {
  toDate() {
    return this;
  }
}

export const getBaseFirebaseDbUrl = (collection, id = undefined) => {
  const projectName = process.env.REACT_APP_FIREBASE_PROJECT_ID;
  const baseURL =
    `https://firestore.googleapis.com/v1/projects/${projectName}/databases/(default)/documents/${collection}` +
    `${id ? `/${id}` : ""}`;
  return baseURL;
};

export const convertFromDocument = (obj) => {
  // https://github.com/firebase/firebase-js-sdk/blob/649e7f3b609c91ceb5357e8f28f22b48a4938eb7/packages/firestore/src/lite-api/user_data_writer.ts#L59
  // function convertValue
  function getFieldValue(field) {
    const fieldType = Object.keys(field)[0];
    switch (fieldType) {
      case "stringValue":
        return field[fieldType];
      // break;
      case "arrayValue":
        const arr = field[fieldType].values;
        const newArr = arr.map((item) => {
          // const itemType = Object.keys(item)[0];
          const itemValue = getFieldValue(item);
          return itemValue;
        });
        return newArr;
      // break;
      case "timestampValue":
        return new CustomDate(field[fieldType]);
      // break;
      default:
        break;
    }
  }
  const objNew = {};
  for (let key in obj.fields) {
    const field = obj.fields[key];
    const fieldValue = getFieldValue(field);
    objNew[key] = fieldValue;
  }
  return objNew;
};

export const convertToDocument = (obj) => {
  // https://github.com/firebase/firebase-js-sdk/blob/649e7f3b609c91ceb5357e8f28f22b48a4938eb7/packages/firestore/src/lite-api/user_data_reader.ts#L838
  // function parseScalarValue
  function convertToDocItem(item) {
    if (typeof item === "string") {
      return { stringValue: item };
    } else if (item instanceof Date) {
      return { timestampValue: item.toISOString() };
    } else if (item instanceof Array) {
      const arr = item;
      const newArr = arr.map((itm) => {
        return convertToDocItem(itm);
      });
      return { arrayValue: { values: newArr } };
    }
  }
  const objNew = { fields: {} };
  for (let key in obj) {
    if (typeof obj[key] === "string") {
      objNew.fields[key] = convertToDocItem(obj[key]);
    } else if (obj[key] instanceof Date) {
      objNew.fields[key] = convertToDocItem(obj[key]);
    } else if (obj[key] instanceof Array) {
      objNew.fields[key] = convertToDocItem(obj[key]);
    }
  }
  return obj ? objNew : null;
};

export const getDocumentId = (doc) => {
  return doc.name.split("/").pop();
};

export const fetchDB = async ({
  method,
  collection,
  id = undefined,
  obj = undefined,
  queryParams = undefined,
  token = undefined,
  controller = undefined,
}) => {
  try {
    const url = new URL(
      `https://firestore.googleapis.com/v1/projects/blog-d518e/databases/(default)/documents/${collection}` +
        `${id ? `/${id}` : ""}`
    );
    url.search = queryParams || "";
    const docObj = convertToDocument(obj);
    // Fetch request with Authorization header will cause for Preflight request
    // So the default of withAPIKey will be false.
    // see the following website for further information about Authorization:
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#examples_of_access_control_scenarios
    const response = await fetch(url, {
      method: method,
      ...(controller && { signal: controller.signal }),
      ...(docObj && { body: JSON.stringify(docObj) }),
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    });
    let responseJson = await response.json();
    if (response.ok) {
      return responseJson;
    } else {
      throw responseJson.error;
    }
  } catch (error) {
    // https://wanago.io/2022/04/11/abort-controller-race-conditions-react/
    // if (controller?.signal?.aborted) {
    //   console.log("The user aborted the request");
    // } else {
    //   console.error("The request failed");
    // }
    throw error;
  }
};
