const register = () => {
  window.addEventListener("load", async () => {
    try {
      await navigator.serviceWorker.register("/service-worker.js");

      console.log("Service worker registered!");
    } catch (err) {
      console.error(err);
    }
  });
};

const unregister = async () => {
  try {
    const registration = await navigator.serviceWorker.ready;

    await registration.unregister();

    console.log("Service worker unregistered!");
  } catch (err) {
    console.error(err);
  }
};

if ("serviceWorker" in navigator) {
  if (process.env.NODE_ENV === "development") unregister();
  else register();
}
