import { useState } from "react";
import makeContext from "src/makeContext";

const [StateProvider, useStateContext, useStateContextUpdater] = makeContext();

export { useStateContext, useStateContextUpdater };

function AuthDialogProvider({ children }) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  return (
    <StateProvider store={dialogIsOpen} setStore={setDialogIsOpen}>
      {children}
    </StateProvider>
  );
}

export default AuthDialogProvider;
