import { useEffect, useState } from "react";
import { HttpClient } from "src/tools/HttpClient/BuildInFetch";

function useFetchCallback(fetchCallback, cacheResponse) {
  const [data, setData] = useState(cacheResponse);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const httpClient = new HttpClient();
    const fetched = fetchCallback(httpClient);
    fetched
      .then((data) => setData(data))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
    return () => httpClient.cancelRequest();
  }, []);

  return { data, setData, loading, error };
}

export default useFetchCallback;
