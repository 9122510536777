import { useMatch, useParams, useResolvedPath } from "react-router-dom";

function usePost(posts) {
  function replaceSpaceInDash(str) {
    return str.replace(/\s+/g, "-");
  }
  function findPostById(postid, posts) {
    const post = posts.find((obj) => obj.id === postid)?.data;
    return post;
  }

  const { postid } = useParams();
  const post = findPostById(postid, posts);
  const titleDashes = post && replaceSpaceInDash(post?.title);
  let { pathname: currentRoutePath } = useResolvedPath("");
  currentRoutePath = currentRoutePath.endsWith("/")
    ? currentRoutePath.slice(1, -1)
    : currentRoutePath.slice(1, currentRoutePath.length);
  const match = useMatch(`${currentRoutePath}/:title/*`) || { params: "" };
  const fixedURL = [currentRoutePath, titleDashes, match.params["*"]].join("/");

  return { post, titleDashes, fixedURL };
}

export default usePost;
