import classNames from "classnames";
import "./Menu.scss";

function Menu(props) {
  const menuClass = classNames(
    "Menu",
    {
      "Menu--clicked": props.isOpen,
    },
    props.className
  );

  return <ul className={menuClass}>{props.children}</ul>;
}

export default Menu;
