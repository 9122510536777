import Menuitem from "../Menuitem/Menuitem";
// import { useCustomLocation } from "src/hooks";
import { useAuth } from "src/contexts/AuthContext";
import classNames from "classnames";
import { useStateContextUpdater } from "src/contexts/AuthDialog/AuthDialog";

function MenuAuthItem(props) {
  const { isUserLoggedIn, currentUser, setCurrentUser } = useAuth();
  const setDialogIsOpen = useStateContextUpdater();

  // const location = useCustomLocation();

  function logout() {
    currentUser.signOut();
    setCurrentUser(null);
    window.location.href = "/";
  }

  return (
    <Menuitem
      className={classNames("MenuAuthItem", props.className)}
      text={!isUserLoggedIn ? "Login" : "Logout"}
      transition={false}
      path="/auth"
      isOpen={props.open}
      // state={!isUserLoggedIn && { backgroundLocation: location }}
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
        !isUserLoggedIn && setDialogIsOpen(true);
        isUserLoggedIn && logout();
      }}
    />
  );
}

export default MenuAuthItem;
