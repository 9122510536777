import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { RestrictedRoute } from "src/components";
import ViewPost from "./components/ViewPost/ViewPost";

const EditPost = lazy(() =>
  import(/* webpackChunkName: 'EditPost' */ "./EditPost/EditPost")
);

export const postRoutes = [
  {
    name: "Post",
    element: <Navigate replace to={"/"} />,
    index: true,
  },
  {
    name: "Title Dashes",
    path: ":currentTitleDashes",
    children: [
      {
        name: "View Post",
        index: true,
        element: <ViewPost />,
        errorElement: <p>hi</p>,
      },
      {
        name: "Edit Post",
        chunkName: "EditPost",
        path: "editpost",
        element: (
          <RestrictedRoute action="ShortPost--editPost">
            <EditPost />
          </RestrictedRoute>
        ),
      },
    ],
  },
];
