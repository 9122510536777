import { useAuth } from "src/contexts/AuthContext";

function usePermissions() {
  const { currentUser, permissionsData } = useAuth();

  const getAllowedGroups = (action) => {
    return permissionsData[action];
  };

  const isUserActionAllowed = (action) => {
    const allowedGroups = getAllowedGroups(action);
    return allowedGroups?.some(
      (group) => group === currentUser?.extraFields?.role
    );
  };

  const returnObject = {
    isUserActionAllowed,
  };

  return returnObject;
}

export default usePermissions;
