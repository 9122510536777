import useFetchCallback from "src/hooks/useFetchCallback/useFetchCallback";
import { fetchAllDocs } from "src/tools/Database/firebaseDBRestAPI";
import { useAuth } from "src/contexts/AuthContext";
// import { user } from "src/contexts/AuthContext/tools/User";

// const user = new User();

function usePostsProvider() {
  const { currentUser } = useAuth();
  const collection = "posts";
  const cacheResponse = JSON.parse(sessionStorage.getItem(collection) || "[]");
  const fetchCallback = (httpClient) => {
    const fetchedDocs = fetchAllDocs(
      collection,
      {
        field: "date",
        direction: "desc",
      },
      {
        httpClient: httpClient,
        user: currentUser,
      }
    );
    fetchedDocs.then((response) => {
      sessionStorage.setItem(collection, JSON.stringify(response));
    });
    return fetchedDocs;
  };
  const { data: posts, setData: setPosts } = useFetchCallback(
    fetchCallback,
    cacheResponse
  );

  return { posts, setPosts };
}

export default usePostsProvider;
